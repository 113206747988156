import { useSelector } from 'react-redux';

export const DEFAULT_MAX_TRAVEL_DISTANCE = 10;

export const useDefaultOperations = () => {
  const { translations } = useSelector((state) => state.domainConfig);
  const locale = translations.substr(0, translations.indexOf('_'));

  return {
    address: null,
    maxTravelDistance: DEFAULT_MAX_TRAVEL_DISTANCE,
    timeframes: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
    blockers: [],
    languages: [locale],
    personalNote: '',
    isSuspended: false,
    published: true,
  };
};
