import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { FiInfo } from 'react-icons/fi';

import { getSelectableLanguages } from '~helpers/common';
import { UserContext } from '~contexts/user';

import Form from '~components/forms/form';

import { CheckboxControlled } from '~components/forms/checkbox';
import { NumericInputControlled } from '~components/forms/numeric-input';
import Button from '~components/button';

import { AvailabilityControlled } from '~components/forms/availability';
import { MaximumDistanceControlled } from '~components/forms/maximum-distance';
import { LanguageSelectorControlled } from '~components/forms/language-selector';
import { TextareaControlled } from '~components/forms/textarea';
import CharacterCount from '~components/forms/character-count';

import { TRAVEL_DISTANCE, MAX_NOTE_LENGTH, MIN_NOTE_LENGTH } from '~src/constants';

import MinimumRateModal from './minimum-rate-modal';

import './style.scss';

const OperationsFields = ({ operations, pricingDetails, defaultPrice, translationsContext, onSubmit = () => {}, isSubmitting = false }) => {
  const intl = useIntl();
  const [isMinimumRateModalOpened, setIsMinimumRateModalOpened] = useState(false);
  const { user } = useContext(UserContext);

  if (!operations) {
    return null;
  }

  const { languages, address, maxTravelDistance, timeframes, blockers, personalNote } = operations;
  const { minPrice, maxPrice, currency } = pricingDetails;

  const step = maxPrice > 10000 ? 1 : 0.25;

  const selectableLanguages = getSelectableLanguages();

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      timeframes,
      minimumRate: defaultPrice / 100,
      maxTravelDistance: maxTravelDistance || 5,
      languages: languages || [],
      blockers: {
        cat: blockers?.indexOf('cat') >= 0,
        dog: blockers?.indexOf('dog') >= 0,
        ironing: blockers?.indexOf('ironing') >= 0,
      },
      personalNote: personalNote || '',
    },
  });

  const validationSchema = Yup.object().shape({
    timeframes: Yup.mixed().test('at-least-one', 'components.form.validation.availability.required', function (v) {
      return v && v.length > 0;
    }),
    languages: Yup.mixed().test('at-least-one', 'components.form.validation.language.required', function (v) {
      return v && v.length > 0;
    }),
    minimumRate: Yup.number()
      .min(minPrice / 100)
      .max(maxPrice / 100),
    maxTravelDistance: Yup.number().min(TRAVEL_DISTANCE.min).max(TRAVEL_DISTANCE.max),
    blockers: Yup.object().shape({ dog: Yup.boolean(), cat: Yup.boolean(), ironing: Yup.boolean() }),
    personalNote: Yup.string().required().min(MIN_NOTE_LENGTH).max(MAX_NOTE_LENGTH).noEmailInContent().noPhoneInContent(),
  });

  const watchNote = methods.watch('personalNote');

  useEffect(() => {
    const selectedRate = methods.getValues('minimumRate');
    if (selectedRate * 100 > maxPrice) {
      methods.setValue('minimumRate', maxPrice / 100);
      return;
    }

    if (selectedRate * 100 < minPrice) {
      methods.setValue('minimumRate', minPrice / 100);
    }
  }, [currency]);

  const handleFormSubmit = () => {
    if (isEmpty(methods.errors) || isSubmitting) {
      const formData = methods.getValues();

      const newOperations = {
        ...operations,
        timeframes: formData.timeframes,
        minimumRate: {
          amount: parseInt(formData.minimumRate * 100, 10),
          currency,
        },
        maxTravelDistance: formData.maxTravelDistance,
        languages: formData.languages,
        blockers: Object.keys(formData.blockers).filter((b) => formData.blockers[b]),
        personalNote: formData.personalNote,
      };
  
      onSubmit(newOperations);
    }
  };

  return (
    <Form
      methods={methods}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
      className="c-form__operations"
    >
      {/* MAXIMUM DISTANCE */}
      <div className="c-form-section c-form-section--center c-form-section--distance" data-cy="qa-distance">
        <div className="c-form-section__header">
          {intl.formatMessage({
            id: 'containers.operations.form.fields.max-travel-distance.headline',
            defaultMessage: 'Maximum travel distance',
          })}
        </div>
        <div className="c-form-section__content">
          <div className="c-fieldset">
            <MaximumDistanceControlled
              name="maxTravelDistance"
              fistName={user?.firstName}
              profilePicture={user?.profilePicture}
              location={address || null}
              min={TRAVEL_DISTANCE.min}
              max={TRAVEL_DISTANCE.max}
              isShowMapDisabled={!address || !address.latitude || !address.longitude}
            />
          </div>
        </div>
      </div>

      {/* MINIMUM RATE */}
      <div className="c-form-section c-form-section--center c-form-section--workload" data-cy="qa-minimum-rate">
        <div className="c-form-section__header">
          {intl.formatMessage({
            id: 'containers.operations.form.fields.minimum-rate.headline',
            defaultMessage: 'Minimum rate',
          })}
          <FiInfo
            className="c-fieldset__info"
            onClick={(ev) => {
              ev.preventDefault();
              setIsMinimumRateModalOpened(true);
            }}
          />
        </div>
        <div className="c-form-section__content">
          <div className="c-fieldset">
            <NumericInputControlled
              min={minPrice / 100}
              max={maxPrice / 100}
              step={step}
              name="minimumRate"
              placeholder={intl.formatMessage({
                id: 'containers.operations.form.fields.minimum-rate.placeholder',
                defaultMessage: 'Minimum rate',
              })}
              format={(d) =>
                intl.formatNumber(d, {
                  style: 'currency',
                  currency,
                })}
            />
            <MinimumRateModal isOpened={isMinimumRateModalOpened} onToggle={setIsMinimumRateModalOpened} />
          </div>
        </div>
      </div>

      {/* AVAILABILITY */}
      <div className="c-form-section c-form-section--center c-form-section--availability" data-cy="qa-availability">
        <div className="c-form-section__header">
          {intl.formatMessage({
            id: 'containers.operations.form.fields.availability.headline',
            defaultMessage: 'At what time are you available for work?',
          })}
        </div>
        <div className="c-form-section__content">
          <div className="c-fieldset">
            <AvailabilityControlled name="timeframes" />
          </div>
        </div>
      </div>

      {/* LANGUAGES SECTION */}
      <div className="c-form-section c-form-section--center c-form-section--languages">
        <div className="c-form-section__header">
          {intl.formatMessage({
            id: 'containers.operations.form.fields.languages.headline',
            defaultMessage: 'Languages',
          })}
        </div>
        <div className="c-form-section__content">
          <div className="c-fieldset" data-cy="languages">
            <LanguageSelectorControlled
              languages={selectableLanguages}
              defaultValues={methods.getValues('languages')}
              name="languages"
            />
          </div>
        </div>
      </div>

      {/* BLOCKERS */}
      <div className="c-form-section c-form-section--center c-form-section--workload" data-cy="qa-blockers">
        <div className="c-form-section__header">
          {intl.formatMessage({
            id: 'containers.operations.form.fields.blockers.headline',
            defaultMessage: 'Last question',
          })}
        </div>
        <div className="c-form-section__content">
          <div className="c-fieldset">
            <div className="c-checkbox__container--horizontal ">
              <CheckboxControlled
                name="blockers.dog"
                defaultChecked={blockers?.indexOf('dog') >= 0}
                label={intl.formatMessage({
                  id: 'containers.operations.form.fields.blockers.dog',
                  defaultMessage: 'I am allergic to dogs',
                })}
              />
              <CheckboxControlled
                name="blockers.cat"
                defaultChecked={blockers?.indexOf('cat') >= 0}
                label={intl.formatMessage({
                  id: 'containers.operations.form.fields.blockers.cat',
                  defaultMessage: 'I am allergic to cats',
                })}
              />
              <CheckboxControlled
                name="blockers.ironing"
                defaultChecked={blockers?.indexOf('ironing') >= 0}
                label={intl.formatMessage({
                  id: 'containers.operations.form.fields.blockers.ironing',
                  defaultMessage: "I don't want to iron",
                })}
              />
            </div>
          </div>
        </div>
      </div>

      {/* PERSONAL NOTE */}
      <div className="c-form-section c-form-section--center c-form-section--vertical c-form-section--pre-last">
        <div className="c-form-section__header">
          {intl.formatMessage({
            id: 'containers.operations.form.fields.personal-note.headline',
            defaultMessage: 'Something about yourself',
          })}
        </div>
        <div className="c-personal-note">
          <CharacterCount
            field={watchNote}
            max={MAX_NOTE_LENGTH}
            className="c-fieldset__info c-fieldset__info--right"
          />
          <div className="c-fieldset" data-cy="personal-note">
            <TextareaControlled
              name="personalNote"
              className="c-personal-data__note-textarea"
              placeholder={intl.formatMessage({
                id: 'containers.operations.form.fields.personal-note.placeholder',
                defaultMessage: 'Tell us something about yourself',
              })}
              isAutoExpand
            />
          </div>
        </div>
      </div>

      <div className="c-form-section c-form-section--center c-form-section--last">
        <Button
          name="submit"
          type="submit"
          className="c-btn"
          disabled={!isEmpty(methods.errors) || isSubmitting}
          isLoading={isSubmitting}
        >
          {intl.formatMessage({
            id: `containers.operations.form.${translationsContext ? `${translationsContext}.` : ''}save`,
            defaultMessage: 'Save',
          })}
        </Button>
      </div>
    </Form>
  );
};

export default OperationsFields;
