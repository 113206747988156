import React from 'react';

import { Slider, Handle, Track, Tick, Rail, Handles, Tracks, Ticks } from '~components/slider';
import { TRAVEL_DISTANCE } from '~src/constants';

const DistanceSlider = ({ min = 1, step = 1, max = TRAVEL_DISTANCE.max, onUpdate = () => {}, onChange = () => {}, value, className }) => {
  const handleChange = (v) => {
    onChange(v[0]);
  };

  const domain = [min, max];
  return (
    <Slider mode={1} step={step} domain={domain} className={className} onUpdate={onUpdate} onChange={handleChange} values={value}>
      <Rail>{({ getRailProps }) => <div className="c-slider__rail-outer" {...getRailProps()} />}</Rail>
      <Handles>
        {({ handles, activeHandleID, getHandleProps }) => (
          <div>
            {handles.map((handle) => (
              <div key={handle.id}>
                <Handle handle={handle} domain={domain} getHandleProps={getHandleProps} isActive={handle.id === activeHandleID}>
                  <div className="c-tooltip">{`${handle.value} km`}</div>
                </Handle>
              </div>
            ))}
          </div>
        )}
      </Handles>
      <Tracks right={false}>
        {({ tracks, getTrackProps }) => (
          <div>
            {tracks.map(({ id, source, target }) => (
              <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
            ))}
          </div>
        )}
      </Tracks>
      <Ticks values={[min, max]}>
        {({ ticks }) => (
          <div>
            {ticks.map((tick) => (
              <Tick key={tick.id} tick={tick} count={ticks.length} format={(d) => `${d} km`} />
            ))}
          </div>
        )}
      </Ticks>
    </Slider>
  );
};

export default DistanceSlider;
