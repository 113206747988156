import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import withControlled from '~components/forms/form/with-controlled';

import DistanceMap from './map';
import DistanceSlider from './slider';

import './style.scss';

export const MaximumDistance = ({
  value,
  errors,
  disabled,
  onChange,
  firstName,
  profilePicture,
  location,
  isShowMapDisabled,
  min,
  max,
}) => {
  const intl = useIntl();
  const [isMapOpened, setIsMapOpened] = useState(false);

  return (
    <>
      <div className="c-fieldset__value c-maximum-distance">
        <DistanceSlider min={min} max={max} step={1} value={[value]} className="c-maximum-distance__slider" onChange={onChange} />
        <div className="c-distance__map-button">
          <button type="button" className="c-btn--text" onClick={() => setIsMapOpened(true)} disabled={isShowMapDisabled}>
            {intl.formatMessage({
              id: 'components.forms.maximum-distance.show-on-map',
              defaultMessage: 'Show on map',
            })}
          </button>
          <DistanceMap
            isOpened={isMapOpened}
            onToggle={setIsMapOpened}
            profilePicture={profilePicture}
            firstName={firstName}
            lat={location?.latitude}
            lng={location?.longitude}
            radius={value}
            onChange={onChange}
          />
        </div>
      </div>
      {errors && !disabled ? <div className="c-input__error l-error">{errors.message}</div> : <></>}
    </>
  );
};

export const MaximumDistanceControlled = withControlled(MaximumDistance);
