import React, { useRef, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useStateFromProp } from '~hooks/common';
import { TRAVEL_DISTANCE } from '~src/constants';

import Modal from '~components/modal';
import Avatar from '~components/avatar';

import DistanceSlider from '../slider';

import './style.scss';

const calcZoom = (distance, width, height) => {
  const pixels = width >= height ? height : width;
  const visibleDistance = Math.abs(distance);
  const equatorLength = 40075016; // in meters
  const zoom256 = Math.log(equatorLength / visibleDistance) / Math.log(2);

  // adapt the zoom to the image size
  const x = parseInt(Math.log(pixels / 256) / Math.log(2), 10);
  const zoom = parseInt(zoom256 + x, 10);
  return zoom;
};

const DistanceMap = ({
  radius = TRAVEL_DISTANCE.max,
  profilePicture,
  firstName,
  lat = 52.3727598,
  lng = 4.2,
  isOpened,
  onToggle = () => {},
  onChange = () => {},
}) => {
  const intl = useIntl();
  const [isModalOpened, setModalOpen] = useStateFromProp(isOpened, onToggle);
  const googleApi = useSelector((state) => state.domainConfig.googleApi);

  const mapReference = useRef();
  const mapsReference = useRef();
  const containerRef = useRef();

  useEffect(() => {
    if (mapReference.current && mapsReference.current) {
      const distanceMapChangeRadiusEvent = new CustomEvent('distanceMapChangeRadiusEvent', { detail: { radius } });
      window.dispatchEvent(distanceMapChangeRadiusEvent);
    }
  }, [radius]);

  return (
    <Modal.Wrapper
      isOpened={isModalOpened}
      onClose={() => setModalOpen()}
      className="c-modal--edgeless c-modal--distance-map"
    >
      <Modal.Content>
        <div className="c-distance-map" ref={containerRef}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: googleApi,
            }}
            defaultCenter={{
              lat,
              lng,
            }}
            defaultZoom={12}
            options={{ scrollwheel: true }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              mapReference.current = map;
              mapsReference.current = maps;
              const fitZoom = calcZoom(35000, containerRef.current.offsetWidth, containerRef.current.offsetHeight);
              map.setZoom(fitZoom);
              const circle = new maps.Circle({
                strokeColor: '#4572f5',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#4572f5',
                fillOpacity: 0.3,
                map,
                center: { lat, lng },
                radius: radius * 1000,
              });
              maps.event.addDomListener(window, 'distanceMapChangeRadiusEvent', (event) => {
                circle.setRadius(event.detail.radius * 1000);
              });
            }}
          >
            <Avatar
              className="c-distance-map__avatar"
              profilePicture={profilePicture}
              firstName={firstName}
              lat={lat}
              lng={lng}
            />
          </GoogleMapReact>
        </div>
        <Modal.Footer>
          <div className="l-modal__container">
            <div className="c-modal__buttons">
              <DistanceSlider
                min={1}
                max={TRAVEL_DISTANCE.max}
                step={1}
                value={[radius]}
                className="c-distance-map__slider"
                onChange={onChange}
              />
              <button type="button" className="c-btn c-distance-map__save" onClick={() => onToggle(false)}>
                {intl.formatMessage({
                  id: 'components.forms.maximum-distance.save',
                  defaultMessage: 'Save',
                })}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Wrapper>
  );
};

export default DistanceMap;
