import React, { useState, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import cx from 'classnames';

import { useLayout } from '~hooks/useLayout';
import { useSeo } from '~hooks/useSeo';
import { useListingRedirect } from '~hooks/useListingRedirect';
import IntakeActions from '~redux/intake';
import { useLocationDetails } from '~hooks/useLocationDetails';
import { getListingRedirect } from '~helpers/common';

import { AuthContext } from '~contexts/auth';
import { UserContext } from '~contexts/user';

import { useDefaultOperations } from '~containers/operations/default-operations';
import OperationsAddress from '~containers/operations/address';
import OperationsFields from '~containers/operations/fields';

import Loader from '~components/loader';
import Breadcrumb from '~components/breadcrumbs';

import '../style.scss';

const WorkerIntake = () => {
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const listingRedirect = useListingRedirect();
  const { signUp } = useContext(AuthContext);
  const { user: authUser } = useAuth0();
  const { user: backendUser, getOwnData, updateWorker } = useContext(UserContext);

  useLayout({ displayCities: false });
  useSeo(intl.formatMessage({ id: 'containers.intake.worker.title', defaultMessage: 'Your work details' }));

  const personId = !authUser ? null : authUser['https://sr2.ca/claims/user_metadata'].uuid;
  const storedOperations = useSelector((state) => state.intake.operations);
  const defaultOperations = useDefaultOperations();
  const operations = storedOperations || defaultOperations;
  const [showFields, setShowFields] = useState(false);
  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [defaultPrice, setDefaultPrice] = useState(operations?.minimumRate?.amount || null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { locationDetails, pricingDetails, isLoading, requestLocationByID } = useLocationDetails(
    queryString.parse(location.search).locationId || operations.address?.place_id || null
  );

  useEffect(() => {
    if (!isLoading) {
      setIsLoadingInitial(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (locationDetails && pricingDetails) {
      if (!defaultPrice) {
        setDefaultPrice((pricingDetails.maxPrice - pricingDetails.minPrice) * 0.25 + pricingDetails.minPrice);
      }

      dispatch(IntakeActions.setAccountType('worker'));
      dispatch(IntakeActions.setOperations({ ...operations, address: locationDetails }));
      setShowFields(true);
    }
  }, [locationDetails, pricingDetails]);

  const handleAddressSubmit = (newAddress) => {
    requestLocationByID(newAddress.place_id);
  };

  const handleSubmit = async (newOperations) => {
    setIsSubmitting(true);
    dispatch(IntakeActions.setOperations(newOperations));
    dispatch(IntakeActions.setAccountType('worker'));

    const isUserAlready = await getOwnData(personId);

    if (!isUserAlready) {
      signUp();
      return;
    }

    const newUser = { ...backendUser, operations: newOperations };
    await updateWorker(newUser);

    setIsSubmitting(false);
    if (storedOperations.referrer) {
      history.push(referrer);
      return;
    }

    const redirectAddress = getListingRedirect('worker', newOperations.address.locationId);
    history.push(redirectAddress);
  };

  return (
    <>
      <section className="c-subheader">
        <div className="l-container">
          <Breadcrumb.Wrapper>
            <Breadcrumb.Crumb>
              <Breadcrumb.CrumbLink to={listingRedirect} index={1}>
                {intl.formatMessage({
                  id: 'breadcrumbs.home',
                })}
              </Breadcrumb.CrumbLink>
            </Breadcrumb.Crumb>
            <Breadcrumb.Crumb>
              <Breadcrumb.CrumbLink to="/intake/worker" index={2}>
                {intl.formatMessage({
                  id: 'containers.intake.worker.breadcrumb',
                })}
              </Breadcrumb.CrumbLink>
            </Breadcrumb.Crumb>
          </Breadcrumb.Wrapper>
        </div>
      </section>
      <div className="c-page l-container">
        <div className="c-page__main">
          <div className="c-page__header c-page__header--minimal">
            <h1 className="l-headline--primary l-center">
              {intl.formatMessage({ id: 'containers.intake.worker.headline', defaultMessage: 'Your work details' })}
            </h1>
          </div>
          <div className={cx('c-form--intake', {'c-form--intake-show-fields': showFields})}>
            <OperationsAddress
              defaultAddress={locationDetails || operations.address}
              onSubmit={handleAddressSubmit}
              isDataLoading={isLoadingInitial}
            />
            {!showFields && isLoading ? (
              <div className="l-center l-mt2">
                <Loader />
              </div>
            ) : null}
            {showFields && (
              <OperationsFields
                operations={operations}
                pricingDetails={pricingDetails}
                defaultPrice={defaultPrice}
                translationsContext="intake"
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkerIntake;
